import styled from 'styled-components';

import * as index from '@/components/Sections/index.styled';

export const Gradient = styled.img`
  position: absolute;
  pointer-events: none;
  width: 100vw;
  max-height: 848px;
`;

export const TopBox = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 50px;
  }
`;

export const Modes = styled.div`
  margin-top: 25px;
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 100px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  & > button {
    border-radius: 100px;
    border: none;
  }
`;

export const CardsBox = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
  column-gap: 20px;
  position: relative;
  z-index: 12;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
  }
`;

export const TableBox = styled.div`
  width: 100%;
  margin: 130px 0 -20px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const FAQSection = styled(index.FAQSection)`
  margin-top: 130px;
  margin-bottom: 0px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin-top: 100px;
    margin-bottom: 0px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    margin-top: 70px;
  }
`;
