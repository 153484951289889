import React, { useEffect, useRef, useState } from 'react';

import * as styled from './Table.styled';

import { TABLE } from '../../constants';

interface ITable {
  $mainPage?: boolean;
}

export const Table: React.FC<ITable> = ({ $mainPage }) => {
  const [open, setOpen] = useState(false);
  const [$tableVisibleHeight, setTableVisibleHeight] = useState(950);

  const tableRef = useRef(null);
  const tableRowRef = useRef([]);

  useEffect(() => {
    if (tableRef?.current) {
      tableRowRef.current = tableRowRef.current.slice(0, TABLE.length);
      let heightCounter = 0;
      for (let i = 0; i <= 4; i++) {
        heightCounter += tableRowRef.current[i].offsetHeight;
      }
      setTableVisibleHeight(heightCounter);
    }
    const onResize = () => {
      if (tableRef?.current) {
        tableRowRef.current = tableRowRef.current.slice(0, TABLE.length);
        let heightCounter = 0;
        for (let i = 0; i <= 4; i++) {
          heightCounter += tableRowRef.current[i].offsetHeight;
        }
        setTableVisibleHeight(heightCounter);
      }
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [tableRef?.current]);

  return (
    <styled.TableSection $mainPage={$mainPage}>
      <styled.TableBox
        open={open}
        $tableVisibleHeight={$tableVisibleHeight}
        $tableHeight={tableRef?.current?.offsetHeight}
      >
        <styled.Table cellSpacing={0} ref={tableRef}>
          <thead>
            {TABLE.map((row, $rowIndex) => {
              if ($rowIndex === 0) {
                return (
                  <styled.TableRow
                    /* eslint-disable no-return-assign */
                    ref={(el) => (tableRowRef.current[$rowIndex] = el)}
                    /* eslint-enable */
                    key={`row-${$rowIndex}`}
                  >
                    {row.map((cell, index) => (
                      <styled.TableHeadCell
                        $index={index}
                        key={`cell-${$rowIndex}-${index}`}
                      >
                        <styled.TableCellContentFull
                          style={{ borderBottom: 'none' }}
                        >
                          <styled.TableHeadCellContent
                            dangerouslySetInnerHTML={{ __html: `${cell}` }}
                            $index={index}
                          />
                        </styled.TableCellContentFull>
                      </styled.TableHeadCell>
                    ))}
                  </styled.TableRow>
                );
              }
              return null;
            })}
          </thead>
          <tbody>
            {TABLE.map((row, $rowIndex) => {
              if ($rowIndex !== 0) {
                return (
                  <styled.TableRow
                    /* eslint-disable no-return-assign */
                    ref={(el) => (tableRowRef.current[$rowIndex] = el)}
                    /* eslint-enable */
                    key={`row-${$rowIndex}`}
                  >
                    {row.map((cell, index) => (
                      <styled.TableCell
                        $index={index}
                        key={`cell-${$rowIndex}-${index}`}
                      >
                        <styled.TableCellContentFull>
                          <styled.TableCellContent
                            dangerouslySetInnerHTML={{ __html: `${cell}` }}
                            $rowIndex={$rowIndex}
                            $index={index}
                          />
                        </styled.TableCellContentFull>
                      </styled.TableCell>
                    ))}
                  </styled.TableRow>
                );
              }
              return null;
            })}
          </tbody>
        </styled.Table>
      </styled.TableBox>
      <styled.MobileTableBox>
        <styled.MobileTable cellSpacing={0}>
          <tbody>
            {[1, 2, 3].map((_, $rowIndex) => (
              <styled.MobileTableRow
                $index={$rowIndex}
                key={`mobileRow-${$rowIndex}`}
              >
                {TABLE.map((row, rowHeadIndex) =>
                  row.map((cell, index) => {
                    if ($rowIndex === index) {
                      return rowHeadIndex === 0 ? (
                        <styled.MobileTableHeadCell
                          dangerouslySetInnerHTML={{ __html: `${cell}` }}
                          $rowIndex={$rowIndex}
                          key={`mobileCell-${$rowIndex}-${index}`}
                        />
                      ) : (
                        <styled.MobileTableCell
                          dangerouslySetInnerHTML={{ __html: `${cell}` }}
                          key={`mobileCell-${$rowIndex}-${index}`}
                        />
                      );
                    }
                    return null;
                  }),
                )}
              </styled.MobileTableRow>
            ))}
          </tbody>
        </styled.MobileTable>
      </styled.MobileTableBox>
      <styled.ShowButtonBox>
        {/* eslint-disable */}
        <span onClick={() => setOpen(!open)}>
          {open ? 'Show less' : 'Show more'}
        </span>
        {/* eslint-enable */}
      </styled.ShowButtonBox>
    </styled.TableSection>
  );
};
