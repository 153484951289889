/* eslint-disable */
import React, { useContext, useState, useRef, useEffect } from 'react';
import { ThemeContext } from 'styled-components';

import icons from '@/images/Sections/icons.png';

import * as styled from './index.styled';

import { removeSpaces } from '@/utils/functions';
import Container from '../Container';
import { Feature, Name, BlurBox } from '../general';
import { CHALLENGES } from '../PartnersProgram/constants';

import {
  Layout,
  Button,
  ButtonColors,
  BorderLink,
  Image,
  Icon,
} from '../general';
import {
  DISCOVER_DATA,
  IHardestData,
  IMainPageProduct,
  mainPageProducts,
  THE_HARDEST_DATA,
} from './constants';
import { Table } from '../PartnersProgram/components';
import { FEATURES } from '../Pricing/constants';
import { Links } from '@/utils/constants';
import { AI } from '../AI';
import { InitialMessage } from './components';

const Sections = () => {
  const theme = useContext(ThemeContext);

  const MAIN_PAGE_PRODUCTS: IMainPageProduct[] = mainPageProducts();

  const [currentProduct, setCurrentProduct] = useState<IMainPageProduct>(
    MAIN_PAGE_PRODUCTS[0],
  );
  const [currentHardestPart, setCurrentHardestPart] = useState<IHardestData>(
    THE_HARDEST_DATA[0],
  );

  const theHardestCardsRef = useRef();
  const theHardestTitleRowRef = useRef(null);
  const hardestRowRef = useRef(null);

  const launchProductRowRef = useRef(null);
  const launchProductCardRef = useRef(null);

  const [launchProductCardWidth, setLaunchProductCardWidth] = useState(0);
  const [clickPressed, setClickPressed] = useState(false);

  useEffect(() => {
    if (launchProductCardRef.current) {
      setLaunchProductCardWidth(launchProductCardRef.current.offsetWidth);
      const onResize = () => {
        setLaunchProductCardWidth(launchProductCardRef?.current?.offsetWidth);
      };
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }
  }, []);

  useEffect(() => {
    if (clickPressed) {
      setTimeout(() => {
        setClickPressed(false);
      }, 1000);
    }
  }, [clickPressed]);

  const onLaunchProductCardClick = (el: IMainPageProduct, index: number) => {
    setCurrentProduct(el);
    setClickPressed(true);

    const current = MAIN_PAGE_PRODUCTS.find(
      (product) => product.title === currentProduct.title,
    );
    const currentIndex = MAIN_PAGE_PRODUCTS.indexOf(current);
    const columnGap = 20;
    const difference = index - currentIndex;
    const row = launchProductRowRef.current;
    const result = difference * (columnGap + launchProductCardWidth);

    row.scrollBy({
      top: 0,
      left: result,
      behavior: 'smooth',
    });
  };

  const onLaunchProductRowScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!clickPressed) {
      //@ts-ignore
      const scrollLeft = e.target.scrollLeft;
      const current = MAIN_PAGE_PRODUCTS.find(
        (product) => product.title === currentProduct.title,
      );
      const currentIndex = MAIN_PAGE_PRODUCTS.indexOf(current);
      const nextIndex = currentIndex + 1;
      const previousIndex = currentIndex - 1;
      const columnGap = 20;
      const offset = launchProductCardWidth / 4;

      if (MAIN_PAGE_PRODUCTS[nextIndex]) {
        const result =
          nextIndex * (launchProductCardWidth + columnGap) - offset;
        if (scrollLeft >= result) {
          if (MAIN_PAGE_PRODUCTS[nextIndex] !== currentProduct) {
            setCurrentProduct(MAIN_PAGE_PRODUCTS[nextIndex]);
          }
        }
      }
      if (MAIN_PAGE_PRODUCTS[previousIndex]) {
        const result =
          previousIndex * (launchProductCardWidth + columnGap) + offset;
        if (scrollLeft < result) {
          if (MAIN_PAGE_PRODUCTS[previousIndex] !== currentProduct) {
            setCurrentProduct(MAIN_PAGE_PRODUCTS[previousIndex]);
          }
        }
      }
    }
  };

  const onHardestCardClick = (el: IHardestData, index: number) => {
    if (window.innerWidth < 3253) {
      setCurrentHardestPart(el);
      setClickPressed(true);
      const currentIndex = THE_HARDEST_DATA.indexOf(currentHardestPart);
      const difference = index - currentIndex;
      let result: number;
      let columnGap = 50;
      let offset: number;
      let cardWidth: number;

      if (window.innerWidth >= 1024) {
        cardWidth = 560;
      } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
        cardWidth = 450;
      } else {
        cardWidth = 310;
        columnGap = 20;
      }
      if (window.innerWidth >= 1300) {
        offset = 350;
      } else if (window.innerWidth < 1300 && window.innerWidth >= 900) {
        offset = 250;
      } else if (window.innerWidth < 900 && window.innerWidth >= 569) {
        offset = 200 - (900 - window.innerWidth) / 10;
      } else if (window.innerWidth < 569 && window.innerWidth >= 450) {
        offset = 200 - (900 - window.innerWidth) / 3.5;
      } else if (window.innerWidth < 450 && window.innerWidth >= 360) {
        offset = 20;
      } else {
        offset = 0;
      }

      if (currentIndex === 0 || currentIndex === THE_HARDEST_DATA.length - 1) {
        if (difference < 0) {
          result = difference * (cardWidth + columnGap) + offset;
        } else {
          result = difference * (cardWidth + columnGap) - offset;
        }
      } else {
        result = difference * (cardWidth + columnGap);
      }

      const row = hardestRowRef.current;
      row.scrollBy({
        top: 0,
        left: result,
        behavior: 'smooth',
      });

      if (window.innerWidth <= 950) {
        const scrollWidth = 80;
        const titleColumnGap = 10;
        const result = difference * (scrollWidth + titleColumnGap);

        const titleRow = theHardestTitleRowRef.current;
        titleRow.scrollBy({
          top: 0,
          left: result,
          behavior: 'smooth',
        });
      }
    }
  };

  const onHardestScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!clickPressed) {
      const currentIndex = THE_HARDEST_DATA.indexOf(currentHardestPart);
      let columnGap = 50;
      let offset: number;
      let cardWidth: number;
      const offsetToMakeLastCardActive = 100;
      const scrollWidth = 80;
      const titleColumnGap = 10;
      const titleRow = theHardestTitleRowRef.current;
      let titleScrollResult: number;

      if (window.innerWidth >= 1024) {
        cardWidth = 560;
      } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
        cardWidth = 450;
      } else {
        cardWidth = 310;
        columnGap = 20;
      }
      if (window.innerWidth >= 1300) {
        offset = 350;
      } else if (window.innerWidth < 1300 && window.innerWidth >= 900) {
        offset = 250;
      } else if (window.innerWidth < 900 && window.innerWidth >= 569) {
        offset = 200 - (900 - window.innerWidth) / 10;
      } else if (window.innerWidth < 569 && window.innerWidth >= 450) {
        offset = 200 - (900 - window.innerWidth) / 3.5;
      } else if (window.innerWidth < 450 && window.innerWidth >= 360) {
        offset = 20;
      } else {
        offset = 0;
      }

      //@ts-ignore
      const scrollLeft = e.target.scrollLeft;
      const nextIndex = currentIndex + 1;
      const previousIndex = currentIndex - 1;
      if (THE_HARDEST_DATA[nextIndex]) {
        let result = nextIndex * (cardWidth + columnGap) - offset;
        if (nextIndex === THE_HARDEST_DATA.length - 1) {
          result =
            currentIndex * (cardWidth + columnGap) -
            offset +
            offsetToMakeLastCardActive;
        }
        if (scrollLeft >= result) {
          if (THE_HARDEST_DATA[nextIndex] !== currentHardestPart) {
            setCurrentHardestPart(THE_HARDEST_DATA[nextIndex]);
            titleScrollResult = scrollWidth + titleColumnGap;
          }
        }
      }
      if (THE_HARDEST_DATA[previousIndex]) {
        let result = previousIndex * (cardWidth + columnGap) - offset;
        if (previousIndex === 0) {
          result =
            currentIndex * (cardWidth + columnGap) -
            offset -
            offsetToMakeLastCardActive;
        }
        if (scrollLeft < result) {
          if (THE_HARDEST_DATA[previousIndex] !== currentHardestPart) {
            setCurrentHardestPart(THE_HARDEST_DATA[previousIndex]);
            titleScrollResult = -(scrollWidth + titleColumnGap);
          }
        }
      }

      if (window.innerWidth <= 950 && titleScrollResult) {
        titleRow.scrollBy({
          top: 0,
          left: titleScrollResult,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <Layout>
      <BlurBox style={{ marginTop: '0px' }}>
        <styled.LaunchSectionContent>
          <styled.LaunchSectionUpperBox>
            <styled.LaunchSectionUpperLeftBox>
              <styled.LauchTitle>
                Launch apps
                <br /> in record time
              </styled.LauchTitle>
              <styled.LaunchDescription>
                Design, build and publish native iOS and Android apps in a few
                clicks. Continue without coding or change the code as a
                professional
              </styled.LaunchDescription>
              <styled.LaunchSectionUpperLeftButtonBox>
                <AI intent="start-building" rel="gs-main">
                  <Button>Start building</Button>
                </AI>
                <Button color={ButtonColors.SECONDARY} to={Links.REQUEST}>
                  Request a demo
                </Button>
              </styled.LaunchSectionUpperLeftButtonBox>
            </styled.LaunchSectionUpperLeftBox>
            <styled.LaunchSectionUpperRightBox>
              <styled.LaunchGradientBox>
                <styled.LaunchSectionUpperRightImageBox>
                  <Image src={icons} />
                </styled.LaunchSectionUpperRightImageBox>
                100% Native Code. No Flutter, ReactNative or other
                cross-platform frameworks inside.{' '}
                <BorderLink
                  color={'#A4A3AD'}
                  to="blog/native-vs-flutter-vs-react-native-why-do-we-go-for-native-swift-and-kotlin-apps"
                >
                  Read why
                </BorderLink>
              </styled.LaunchGradientBox>
            </styled.LaunchSectionUpperRightBox>
          </styled.LaunchSectionUpperBox>
          <styled.LaunchLowerBox>
            <styled.LaunchIconRowBox>
              {MAIN_PAGE_PRODUCTS.map((product) => (
                <styled.LaunchIconBox
                  key={product.title}
                  onClick={() => setCurrentProduct(product)}
                  $active={product.title === currentProduct.title}
                >
                  <Icon name={product.icon} />
                </styled.LaunchIconBox>
              ))}
            </styled.LaunchIconRowBox>
            <styled.LaunchDesktopRowBox>
              {MAIN_PAGE_PRODUCTS.map((product) => (
                <styled.LaunchDesktopProductsCardBox
                  key={product.title}
                  $active={product.title === currentProduct.title}
                >
                  <styled.LaunchProductCardBox>
                    <styled.LaunchProductCardTextBox>
                      <div>
                        <styled.LaunchProductCardDescription>
                          {product.description}
                        </styled.LaunchProductCardDescription>
                        <styled.LaunchProductCardTitle>
                          {product.title}
                        </styled.LaunchProductCardTitle>
                        <styled.LaunchProductCardList>
                          {product.list.map((el) => (
                            <styled.LaunchProductCardListItem
                              key={`LaunchProductCardListItem-${el}`}
                            >
                              {el}
                            </styled.LaunchProductCardListItem>
                          ))}
                        </styled.LaunchProductCardList>
                      </div>
                      <styled.LaunchProductCardLearnButtonBox>
                        <Button
                          color={ButtonColors.SECONDARY}
                          to={
                            product.to
                              ? product.to
                              : `${removeSpaces(product.title)}`
                          }
                        >
                          Learn more
                        </Button>
                      </styled.LaunchProductCardLearnButtonBox>
                    </styled.LaunchProductCardTextBox>
                    <styled.LaunchProductCardImageBox>
                      {currentProduct.title === 'AI App Builder' && (
                        <InitialMessage />
                      )}
                      <Image
                        src={product.image}
                        height="100%"
                        width={`${product.imageWidth}px`}
                      />
                    </styled.LaunchProductCardImageBox>
                  </styled.LaunchProductCardBox>
                </styled.LaunchDesktopProductsCardBox>
              ))}
            </styled.LaunchDesktopRowBox>
            <styled.LaunchMobileProductsCarouselWrapper>
              <div>
                <styled.LaunchMobileProductsCarouselBox>
                  <styled.LaunchMobileProductsCarousel
                    ref={launchProductRowRef}
                    onScroll={(e) => onLaunchProductRowScroll(e)}
                  >
                    <div>
                      {MAIN_PAGE_PRODUCTS.map((product) => (
                        <styled.LaunchProductCardBox
                          key={product.title}
                          ref={launchProductCardRef}
                        >
                          <styled.LaunchIconBox>
                            <Icon name={product.icon} />
                          </styled.LaunchIconBox>
                          <styled.LaunchProductCardTextBox>
                            <div>
                              <styled.LaunchProductCardDescription>
                                {product.description}
                              </styled.LaunchProductCardDescription>
                              <styled.LaunchProductCardTitle>
                                {product.title}
                              </styled.LaunchProductCardTitle>
                              <styled.LaunchProductCardList>
                                {product.list.map((el) => (
                                  <styled.LaunchProductCardListItem
                                    key={`LaunchProductCardListItem-${el}`}
                                  >
                                    {el}
                                  </styled.LaunchProductCardListItem>
                                ))}
                              </styled.LaunchProductCardList>
                            </div>
                            <styled.LaunchProductCardLearnButtonBox>
                              <Button
                                color={ButtonColors.SECONDARY}
                                to={
                                  product.to
                                    ? product.to
                                    : `${removeSpaces(product.title)}`
                                }
                              >
                                Learn more
                              </Button>
                            </styled.LaunchProductCardLearnButtonBox>
                          </styled.LaunchProductCardTextBox>
                        </styled.LaunchProductCardBox>
                      ))}
                    </div>
                  </styled.LaunchMobileProductsCarousel>
                  <styled.LaunchProductCardDotsRow>
                    {MAIN_PAGE_PRODUCTS.map((product, index) => (
                      <styled.LaunchProductCardDotBox
                        key={`mobile-${product.title}`}
                        onClick={() => onLaunchProductCardClick(product, index)}
                      >
                        <styled.LaunchProductCardDot
                          $active={product.title === currentProduct.title}
                        />
                      </styled.LaunchProductCardDotBox>
                    ))}
                  </styled.LaunchProductCardDotsRow>
                </styled.LaunchMobileProductsCarouselBox>
              </div>
            </styled.LaunchMobileProductsCarouselWrapper>
          </styled.LaunchLowerBox>
        </styled.LaunchSectionContent>
      </BlurBox>
      <styled.HardestSection>
        <Container style={{ zIndex: '2', position: 'relative' }}>
          <styled.HardestContent>
            <styled.HardestTitle>
              The hardest parts of mobile applications developed for you
            </styled.HardestTitle>
            <styled.HardestTitleRowBox ref={theHardestTitleRowRef}>
              {THE_HARDEST_DATA.map((el, index) => (
                <styled.HedestCardTitle
                  key={el.title}
                  $active={el === currentHardestPart}
                  onClick={() => onHardestCardClick(el, index)}
                >
                  {el.title}
                </styled.HedestCardTitle>
              ))}
            </styled.HardestTitleRowBox>
            <styled.HardestRowBox
              ref={hardestRowRef}
              onScroll={(e) => onHardestScroll(e)}
            >
              {THE_HARDEST_DATA.map((el, index) => (
                <styled.HardestCardBox
                  key={`row-${el.title}`}
                  $index={index}
                  ref={theHardestCardsRef}
                >
                  <styled.HardestCardImageBox>
                    <Image src={el.image} />
                    {el.secondaryImages?.map((secondaryImage, i) => (
                      <styled.HardestCardSecondaryImageBox
                        key={`row-${el.title}-image-${i}`}
                        $left={secondaryImage.left}
                        $right={secondaryImage.right}
                        $top={secondaryImage.top}
                        $width={secondaryImage.width}
                      >
                        <Image src={secondaryImage.image} />
                      </styled.HardestCardSecondaryImageBox>
                    ))}
                  </styled.HardestCardImageBox>
                  <styled.HardestCardDescription>
                    {el.description}
                  </styled.HardestCardDescription>
                </styled.HardestCardBox>
              ))}
            </styled.HardestRowBox>
          </styled.HardestContent>
        </Container>
        <styled.HardestBottomRound>
          <Icon name={Name.GENERAL_HARDEST_ROUND} />
        </styled.HardestBottomRound>
      </styled.HardestSection>
      <styled.DiscoverSection>
        <styled.Title>Discover what you can build</styled.Title>
        <styled.DiscoverUnderTitle>
          <styled.Text>Try a demo of CodePlatform firsthand</styled.Text>
          <Button color={ButtonColors.SECONDARY} to={Links.TEMPLATES}>
            Explore templates
          </Button>
        </styled.DiscoverUnderTitle>
        <styled.DiscoverRow>
          {DISCOVER_DATA.map((el, index) => (
            <styled.DiscoverFullCardBox key={el.title}>
              <styled.DiscoverCardBox $index={index}>
                <styled.DiscoverImageBox>
                  <Image src={el.image} />
                </styled.DiscoverImageBox>
                <Button
                  color={ButtonColors.SECONDARY}
                  $leftIcon={
                    <Icon name={Name.MAIN_PAGE_DISCOVER_RUN_PREVIEW} />
                  }
                  to={`${Links.TEMPLATES}/${removeSpaces(el.title)}/preview`}
                  blank
                >
                  Run preview
                </Button>
              </styled.DiscoverCardBox>
              <styled.DiscoverCardUnderBox
                to={`${Links.TEMPLATES}/${removeSpaces(el.title)}`}
              >
                <styled.DiscoverIconBox $index={index}>
                  <Icon name={el.icon} />
                </styled.DiscoverIconBox>
                <div>
                  <styled.Text as="h4" $weight={theme.weights.bold}>
                    {el.title}
                  </styled.Text>
                  <styled.Text color={theme.colors.grayText}>
                    {el.description}
                  </styled.Text>
                </div>
              </styled.DiscoverCardUnderBox>
            </styled.DiscoverFullCardBox>
          ))}
        </styled.DiscoverRow>
      </styled.DiscoverSection>
      <styled.TableSection>
        <styled.Title>CodePlatform vs custom development</styled.Title>
        <styled.Text style={{ maxWidth: '750px', textAlign: 'center' }}>
          CodePlatform saves at least 480 hours of development by excluding an
          expensive initial phase. It covers all you need to start with. Even
          the pitfalls.
        </styled.Text>
        <styled.TableBox>
          <Table $mainPage />
        </styled.TableBox>
      </styled.TableSection>
      <styled.BlueSection>
        <Container>
          <styled.BlueBox>
            <styled.BlueBoxContent>
              <styled.BlueTextBox>
                <styled.BlueTitle>
                  Need a hand with development?
                </styled.BlueTitle>
                <styled.BlueDescription>
                  Ask our experts if you need help building your web or mobile
                  app, unique pixel-perfect design, custom feature development
                  or integration. We would be happy to help with your next
                  project as well as step-by-step legacy app migration.
                </styled.BlueDescription>
                <styled.BlueLink to={Links.EXPERTS} color={theme.colors.white}>
                  Explore experts
                </styled.BlueLink>
              </styled.BlueTextBox>
              <styled.BlueGrid>
                {CHALLENGES.map((challenge) => (
                  <styled.GridItem key={challenge.title}>
                    <styled.GridTitle>{challenge.title}</styled.GridTitle>
                    <Icon name={challenge.icon} width={50} height={50} />
                  </styled.GridItem>
                ))}
              </styled.BlueGrid>
            </styled.BlueBoxContent>
            <styled.BlueBlurEllipse />
          </styled.BlueBox>
        </Container>
      </styled.BlueSection>
      <styled.FAQSection>
        <styled.Title>Still have questions?</styled.Title>
        <styled.FAQBox>
          {FEATURES.map((feature) => (
            <Feature
              key={feature.question}
              question={feature.question}
              answer={feature.answer}
              linkReg={feature.linkReg}
              links={feature.links}
              style={{ borderRadius: '16px' }}
              $mainPage
            />
          ))}
        </styled.FAQBox>
        <styled.StickyButtonBox>
          <AI intent="start-building" rel="gs-bottom">
            <Button>Start building</Button>
          </AI>
        </styled.StickyButtonBox>
      </styled.FAQSection>
    </Layout>
  );
};
export default Sections;
