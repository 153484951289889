import React, { useRef, useEffect, useState } from 'react';
import { getImage } from 'gatsby-plugin-image';

import { Button, ButtonColors } from '@/components/general';
import { removeSpaces } from '@/utils/functions';
import { Links, LocalKeys } from '@/utils/constants';
import { IExpertInfo } from '../../types';
import { Rating } from '../Rating';

import * as styled from './Card.styled';

interface ICard {
  card: IExpertInfo;
  $isSlug?: boolean;
  externalIndex: number | string;
}

export const Card: React.FC<ICard> = ({ card, $isSlug, externalIndex }) => {
  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const [lines, setLines] = useState(0);

  useEffect(() => {
    if (titleRef?.current) {
      const titleHeight = titleRef.current.offsetHeight;
      const linesOffset = Math.round(titleHeight / 28);
      const linesResult = 6 - linesOffset;
      setLines(linesResult);
    }
  }, [titleRef]);

  const contactExpert = () =>
    sessionStorage.setItem(LocalKeys.SELECTED_EXPERT, card.companyName);

  return (
    <styled.Wrapper $isSlug={$isSlug}>
      {!$isSlug && <styled.LinkAnchor to={removeSpaces(card.companyName)} />}
      <styled.Top>
        <styled.TopImage
          image={getImage(card.background)}
          alt="background-image"
        />
        <styled.Role>{card.role}</styled.Role>
        <styled.Logo src={card.avatar.url} alt="avatar" />
      </styled.Top>
      <styled.Bottom>
        <styled.Name ref={titleRef}>{card.companyName}</styled.Name>
        <styled.Agency>{card.agency}</styled.Agency>
        <styled.Description lines={$isSlug ? undefined : lines}>
          {card.shortDescription}
        </styled.Description>
        <Rating
          $isSlug={$isSlug}
          reviews={card.reviews}
          externalIndex={externalIndex}
        />
        <styled.ButtonBox>
          {card.websiteLink && (
            <Button to={card.websiteLink} blank color={ButtonColors.SECONDARY}>
              Visit website
            </Button>
          )}
          <Button onClick={contactExpert} to={Links.TALK}>
            Contact
          </Button>
        </styled.ButtonBox>
      </styled.Bottom>
    </styled.Wrapper>
  );
};
