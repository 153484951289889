import React from 'react';
import { useForm } from 'react-hubspot';

import * as general from '@/components/general/FormPages.styled';

import { LocalKeys } from '@/utils/constants';
import { Form, Layout, BlurBox } from '@/components/general';
import { FormBox } from './Content.styled';

export const Content = () => {
  const isBrowser = () => typeof window !== 'undefined';
  const formData = useForm({
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
    formId: process.env.GATSBY_HUBSPOT_TALK_TO_EXPERTS_FORM_ID,
  });
  const selectedExpert = isBrowser()
    ? sessionStorage.getItem(LocalKeys.SELECTED_EXPERT)
    : '';
  const pricingPlan = isBrowser()
    ? sessionStorage.getItem(LocalKeys.PRICING_PLAN)
    : '';
  const period = isBrowser()
    ? sessionStorage.getItem(LocalKeys.APP_PERIOD)
    : '';

  return (
    <Layout $flexGrow>
      <BlurBox $maxHeightNone>
        <general.Title>Contact Us</general.Title>
        <general.UnderTitle>
          Get a free consultation from our experts before getting started.{' '}
          <br /> We are happy to help you choose the right solution, and collect
          your feedback about our platform.
        </general.UnderTitle>
        <FormBox>
          <Form
            id="talk-to-experts"
            inputs={[
              {
                placeholder: 'Subject',
                name: 'subject',
                value: 'Talk to sales',
                hidden: true,
              },
              {
                placeholder: 'Selected plan',
                name: 'selected_plan',
                value: `${pricingPlan} / ${period}`,
                hidden: true,
              },
              {
                placeholder: 'Expert',
                name: 'selected_expert',
                value: selectedExpert,
                hidden: true,
              },
              { placeholder: 'Name*', name: 'firstname' },
              { placeholder: 'Company Name*', name: 'company' },
              {
                placeholder: 'Work email*',
                type: 'email',
                name: 'email',
              },
              { placeholder: 'Job Title*', name: 'jobtitle' },
            ]}
            formData={{
              data: formData.data,
              handleSubmit: formData.handleSubmit,
              isLoading: formData.isLoading,
            }}
            textareaText="Tell us more about your company and project"
            buttonText="Let's Talk"
          />
        </FormBox>
      </BlurBox>
    </Layout>
  );
};
