import React from 'react';

import * as styled from './Table.styled';

import { TableBody } from './TableBody';
import { TableHead } from './TableHead';
import { TABLE_DATA } from './constants';

export const Table: React.FC = () => (
  <styled.TableBox>
    <TableHead />
    {TABLE_DATA.map((section) => (
      <TableBody key={section.title} section={section} />
    ))}
  </styled.TableBox>
);
