import React, { useState } from 'react';

import gradient from '@/images/Pricing/gradient.png';

import * as index from '@/components/Sections/index.styled';
import { Links } from '@/utils/constants';
import * as styled from './Content.styled';
import { Title, UnderTitle } from '../general/FormPages.styled';

import {
  Breadcrumbs,
  Layout,
  Feature,
  BlueBox,
  Button,
  ButtonColors,
} from '../general';
import { Card, Table } from './components';
import { BUILD_FOR_ME, DO_IT_YOURSELF, FEATURES } from './constants';

export const Content: React.FC = () => {
  const [buildForMeMode, setBuildForMeMode] = useState(false);
  const [yearly, setYearly] = useState(false);

  return (
    <Layout>
      <Breadcrumbs links={[Links.PRICING]} />
      <styled.Gradient src={gradient} alt={gradient} />
      <styled.TopBox>
        <Title>Pricing</Title>
        <UnderTitle>Build, publish, and host native apps with ease.</UnderTitle>
        <styled.Modes>
          <Button
            height={36}
            color={buildForMeMode ? ButtonColors.SECONDARY : ButtonColors.BLACK}
            onClick={() => setBuildForMeMode(false)}
          >
            Do it yourself
          </Button>
          <Button
            height={36}
            color={buildForMeMode ? ButtonColors.BLACK : ButtonColors.SECONDARY}
            onClick={() => setBuildForMeMode(true)}
          >
            Build for me
          </Button>
        </styled.Modes>
      </styled.TopBox>
      {buildForMeMode ? (
        <styled.CardsBox>
          {BUILD_FOR_ME.map((card) => (
            <Card
              key={card.title}
              card={card}
              buildForMeMode
              yearly={yearly}
              setYearly={setYearly}
            />
          ))}
        </styled.CardsBox>
      ) : (
        <>
          <styled.CardsBox>
            {DO_IT_YOURSELF.map((card) => (
              <Card
                key={card.title}
                card={card}
                yearly={yearly}
                setYearly={setYearly}
              />
            ))}
          </styled.CardsBox>
          <styled.TableBox>
            <index.Title>Compare features</index.Title>
            <Table />
          </styled.TableBox>
          <BlueBox
            title="CodePlatform Partners Program"
            description="We get the request - you get the lead. Explore new market opportunities  and accelerate development as a part or a partner of our growing  ecosystem."
            hasCaption={false}
            learnMoreLink={Links.PARTNERS_PROGRAM}
          />
        </>
      )}
      <styled.FAQSection>
        <index.Title>Still have questions?</index.Title>
        <index.FAQBox>
          {FEATURES.map((feature) => (
            <Feature
              key={feature.question}
              question={feature.question}
              answer={feature.answer}
              linkReg={feature.linkReg}
              links={feature.links}
              style={{ borderRadius: '16px' }}
              $mainPage
            />
          ))}
        </index.FAQBox>
      </styled.FAQSection>
    </Layout>
  );
};
