import React from 'react';
import { graphql } from 'gatsby';

import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import Content from '@/components/Blog/BlogArticle/Content';
import { Footer } from '@/components/Footer';
import { Links } from '@/utils/constants';
import { Meta } from '@/components/general/Meta';
import { removeSpaces } from '@/utils/functions';

const BlogArticle = ({ pageContext, data }) => {
  const articleData = data.contentfulArticle;

  return (
    <>
      <Meta
        title={`${pageContext.pageTitle} | CodePlatform`}
        description={articleData.description.description}
        author={articleData.author.name}
        image={articleData.primaryImage.url}
        url={`${Links.BLOG}/${removeSpaces(pageContext.pageTitle)}`}
      />
      <GlobalStyle />
      <Wrapper $overflowNotHidden>
        <Header currentpath={Links.BLOG} />
        <Content data={data} />
        <Footer />
      </Wrapper>
    </>
  );
};

export default BlogArticle;

export const query = graphql`
  query ArticleQuery($pageId: String) {
    contentfulArticle(id: { eq: $pageId }) {
      id
      title
      primaryImage {
        url
      }
      date
      tags
      description {
        description
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1500)
            __typename
          }
          ... on ContentfulList {
            contentful_id
            options
            listStyleMarker {
              url
            }
          }
        }
      }
      author {
        name
        jobTitle
        photo {
          url
        }
        authorLinks {
          text
          link
        }
      }
      faq {
        id
        question
        answer {
          answer
        }
      }
      upNextArticle {
        title
        description {
          description
        }
        tags
        primaryImage {
          url
        }
      }
    }
    contentfulBlogPage {
      articles {
        id
        title
        description {
          description
        }
        tags
        primaryImage {
          url
        }
      }
    }
  }
`;
