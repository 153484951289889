import styled from 'styled-components';

export const TableBox = styled.div`
  width: 100%;
  max-width: 1090px;
  margin: 45px auto 0;
  font-size: 17px;
  line-height: 20.4px;
  overflow: hidden;
  transition: ${(props) => props.theme.transition.primary};
`;

export const TableHead = styled.div`
  display: flex;
  padding: 10px 15px;
  border-radius: 8px;
  background: linear-gradient(90deg, #e6e9f0 0%, #eef1f5 100%);
  font-weight: ${(props) => props.theme.weights.regular};
  & > div {
    width: calc(100% / 4);
    color: ${(props) => props.theme.colors.darkText};
    & b {
      font-weight: ${(props) => props.theme.weights.bold};
    }
  }
`;

export const Table = styled.div`
  margin: 0 15px 20px;
  & h2 {
    color: ${(props) => props.theme.colors.grayText};
    font-size: 22px;
    line-height: 26.4px;
    font-weight: ${(props) => props.theme.weights.bold};
    margin: 40px 0 5px;
  }
`;

export const TableRow = styled.div`
  display: flex;
  gap: 15px;
  padding: 15px 0;
  color: ${(props) => props.theme.colors.darkText};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  & > div {
    width: calc(100% / 4);
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: ${(props) => props.theme.weights.regular};
    &:first-child {
      font-weight: ${(props) => props.theme.weights.bold};
    }
    & svg {
      min-width: 20px;
      min-height: 20px;
    }
    & span {
      & > mark {
        color: ${(props) => props.theme.colors.grayText};
        background: transparent;
      }
    }
  }
`;
