import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';
import { ICardTypeColor } from '../../constants';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Title = styled.h3`
  ${text.FS20}
  font-weight: ${(props) => props.theme.weights.bold};
  line-height: normal;
`;

interface IPrice {
  $buildForMeMode?: boolean;
}

export const Price = styled.div<IPrice>`
  ${text.FS30}
  font-weight: ${(props) => props.theme.weights.bold};
  line-height: normal;
  margin: ${(props) => (props.$buildForMeMode ? '5px 0 16px' : '5px 0')};
`;

export const Description = styled.div`
  ${text.FS18}
  max-width: 468px;
  font-weight: ${(props) => props.theme.weights.regular};
  line-height: normal;
  margin-bottom: 15px;
  color: ${(props) => props.theme.colors.grayText};
`;

interface ISmallDescription {
  $semiBold?: boolean;
}

export const SmallDescription = styled.span<ISmallDescription>`
  ${text.FS14}
  font-weight: ${(props) => props.$semiBold && props.theme.weights.semiBold};
  color: ${(props) => props.theme.colors.grayText};
`;

interface IButtonBox {
  $buildForMeMode?: boolean;
}

export const ButtonBox = styled.div<IButtonBox>`
  margin-bottom: ${(props) => (props.$buildForMeMode ? 35 : 20)}px;
  max-width: ${(props) => props.$buildForMeMode && '165px'};
  & > * {
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    max-width: unset;
  }
`;

interface IFeaturesHeading {
  $buildForMeMode?: boolean;
}

export const FeaturesHeading = styled.p<IFeaturesHeading>`
  ${text.FS18}
  max-width: ${(props) => props.$buildForMeMode && '165px'};
  font-weight: ${(props) => props.theme.weights.bold};
  line-height: normal;
  margin-bottom: 15px;
  padding-top: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorderColor};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    max-width: unset;
  }
`;

export const FeaturesList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ListItem = styled.li`
  display: flex;
  gap: 5px;
`;

export const VoteBox = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
`;

export const ItemText = styled.div`
  margin-top: 2px;
  font-size: 14px;
  font-weight: ${(props) => props.theme.weights.semiBold};
  line-height: 145%;
`;

export const Image = styled.img`
  pointer-events: none;
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: calc(100% - 130px);

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: none;
  }
`;

interface IBox {
  $buildForMeMode?: boolean;
  $color: ICardTypeColor;
}

export const Box = styled.div<IBox>`
  position: relative;
  width: 350px;
  padding: 30px 30px 25px;
  border-radius: 24px;
  border: 1px solid transparent;
  background-color: ${(props) => props.theme.colors.lightBlue};
  color: ${(props) => props.theme.colors.black};
  ${(props) => {
    switch (props.$color) {
      case ICardTypeColor.blue:
        return `
        width: ${props.$buildForMeMode && '640px'};
        background: ${
          props.$buildForMeMode
            ? `linear-gradient(145deg, ${props.theme.colors.blue} 0%, ${props.theme.colors.lightBlue} 150%)`
            : props.theme.colors.blue
        };
        border: ${props.$buildForMeMode && 'unset'};
        padding: ${props.$buildForMeMode && '31px 31px 26px'};
        color: ${props.theme.colors.white};
        ${TitleWrapper} > label {
          color: ${props.theme.colors.white};
        }
        ${Description}, ${SmallDescription} {
          color: ${props.theme.colors.offWhite};
        }
        ${VoteBox} {
          & path {
            stroke: ${props.theme.colors.white};
          }
        }
        `;
      case ICardTypeColor.white:
        return `
        width: ${props.$buildForMeMode && '430px'};
        background: ${props.theme.colors.white};
        border: 1px solid ${props.theme.colors.borderColor};
        `;
    }
  }}

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    width: 500px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
  }
`;
