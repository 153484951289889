import React from 'react';

import { Meta } from '@/components/general/Meta';
import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import { Content } from '@/components/Pricing';
import { Footer } from '@/components/Footer';
import { Links } from '@/utils/constants';

const Pricing = () => (
  <>
    <Meta
      title="Pricing | CodePlatform"
      description="Find a plan to accelerate your project development"
      url={Links.PRICING}
    />
    <GlobalStyle />
    <Wrapper>
      <Header currentpath={Links.PRICING} />
      <Content />
      <Footer />
    </Wrapper>
  </>
);

export default Pricing;
