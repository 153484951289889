import React, { ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

export interface TransitionTheme {
  primary: string;
  scrollAnimation: string;
}

export enum Weights {
  REGULAR = '400',
  SEMI_BOLD = '600',
  BOLD = '700',
  EXTRA_BOLD = '800',
}
export interface WeightTheme {
  regular: string;
  semiBold: string;
  bold: string;
  extraBold: string;
}

export interface ColorTheme {
  white: string;
  offWhite: string;
  lightGray: string;
  black: string;
  offBlack: string;
  blue: string;
  lightBlue: string;
  green: string;
  paleBlue: string;
  lightGray1: string;
  lightGray2: string;
  tagBgGray: string;
  borderGray: string;
  videoBgGray: string;
  grayText: string;
  yellow: string;
  orange: string;

  offWhite1: string;
  offGreen: string;
  secondaryBlack: string;
  secondaryBlueHover: string;
  primaryBackground: string;
  disabledBgColor: string;
  disabledTextColor: string;
  borderColor: string;
  secondaryBorderColor: string;
  hoverWhite: string;
}

export interface FontSizesTheme {
  defaultSize: number;

  // from 0 to 20
  s1: number;
  s2: number;
  s3: number;
  s4: number;

  // from 21 to 40
  m1: number;
  m2: number;
  m3: number;
}

export interface LineHeightsTheme {
  // from 0 to 20
  s1: number;
  s2: number;
  s3: number;

  // from 21 to 40
  m1: number;
  m2: number;
  m3: number;
  m4: number;

  // from 41
  l1: number;
}

export interface BreakpointsTheme {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

export interface SpacingTheme {
  // from 0 to 20
  s1: number;
  // from 20to 40
  m1: number;
  m2: number;
  m3: number;
  // from 40 to 60
  l1: number;
  l2: number;
  // from 60 to 120 xl
  xl1: number;
  xl2: number;
  // from 120 and more
  xxl1: number;
  xxl2: number;
  xxl3: number;
}

export interface BorderRadiusTheme {
  xss: number;
  xs: number;
  sm: number;
  md: number;
  xxl: number;
}

export interface HeaderSize {
  paddingPrimary: number;
  paddingTopMobile: number;
  paddingBottomMobile: number;
  heightDesktop: number;
  heightMobile: number;
}

export interface BorderWidth {
  sm: number;
}
export interface LogoSize {
  h: number;
  w: number;
}
export interface CodeTheme {
  colors: ColorTheme;
  transition: TransitionTheme;
  weights: WeightTheme;
  fontSize: FontSizesTheme;
  lineHeight: LineHeightsTheme;
  breakpoints: BreakpointsTheme;
  spacing: SpacingTheme;
  borderRadius: BorderRadiusTheme;
  borderWidth: BorderWidth;
  headerSize: HeaderSize;
  logoSize: LogoSize;
}

export const CodeTheme: CodeTheme = {
  colors: {
    white: '#FFFFFF',
    paleBlue: '#CADDFF',
    offWhite: '#DAE2FB',
    lightGray: '#F5F5F5',
    lightGray1: '#f8f8f8',
    lightGray2: '#f4f4f4',
    // tagBgGray: '#edeeef',
    tagBgGray: '#e3e6e6',
    borderGray: '#E6E6E6',
    videoBgGray: '#e2e2e2',
    grayText: '#7B7B8D',
    offBlack: '#626366',
    // black: '#1F2024',
    black: '#212133',
    blue: '#737BF8',
    lightBlue: '#E9ECFC',
    green: '#00A500',
    yellow: '#F9D624',
    orange: '#F99F04',

    secondaryBlack: '#212133',
    secondaryBlueHover: '#444FF5',
    primaryBackground: '#F2F2F3',
    offGreen: '#EAF6F5',
    offWhite1: '#B7B7C9',
    disabledBgColor: '#E8E8EE',
    disabledTextColor: '#BEBED5',
    borderColor: '#DFDFEA',
    secondaryBorderColor: '#CBCEDE',
    hoverWhite: '#F2F1F3',
  },
  transition: {
    primary: 'all 0.4s ease',
    scrollAnimation: 'all 0.7s ease-out',
  },
  weights: {
    regular: Weights.REGULAR,
    semiBold: Weights.SEMI_BOLD,
    bold: Weights.BOLD,
    extraBold: Weights.EXTRA_BOLD,
  },
  fontSize: {
    defaultSize: 18,

    s1: 12,
    s2: 14,
    s3: 16,
    s4: 18,

    m1: 22,
    m2: 30,
    m3: 40,
  },
  lineHeight: {
    s1: 14,
    s2: 16,
    s3: 19,

    m1: 21,
    m2: 26,
    m3: 32,
    m4: 35,

    l1: 47,
  },
  breakpoints: {
    xs: 390,
    sm: 568,
    md: 767,
    lg: 1023,
    xl: 1200,
    xxl: 1450,
  },
  spacing: {
    s1: 20,

    m1: 30,
    m2: 35,
    m3: 40,

    l1: 50,
    l2: 60,

    xl1: 65,
    xl2: 110,

    xxl1: 160,
    xxl2: 206,
    xxl3: 245,
  },
  borderRadius: {
    xss: 6,
    xs: 10,
    sm: 15,
    md: 20,
    xxl: 100,
  },
  borderWidth: {
    sm: 2,
  },
  headerSize: {
    paddingPrimary: 34,
    paddingTopMobile: 24,
    paddingBottomMobile: 20,
    // 34 (padding-top) + 36 (button) + 2*2 (border) + 17 (padding-bottom)
    heightDesktop: 70,
    // 22 (padding-top) + 36 (logo) + 11 (padding-bottom)
    heightMobile: 70,
  },
  logoSize: {
    h: 36,
    w: 40,
  },
};

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => <StyledThemeProvider theme={CodeTheme}>{children}</StyledThemeProvider>;
