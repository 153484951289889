import React from 'react';

import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import Sections from '@/components/Sections';
import { Footer } from '@/components/Footer';
import { Links } from '@/utils/constants';
import { Meta } from '@/components/general/Meta';
import logo from '@/images/logo.png';

const Home = () => (
  <>
    <Meta
      title="The fastest way to build native apps with low-code | CodePlatform"
      description="Save months of development, thousands of dollars with Swift & Kotlin based low-code platform"
      structuredData={`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "CodePlatform",
            "url": "https://codeplatform.com",
            "logo": "https://codeplatform.com${logo}"
          }
        `}
    />
    <GlobalStyle />
    <Wrapper>
      <Header currentpath={Links.HOME} />
      <Sections />
      <Footer />
    </Wrapper>
  </>
);

export default Home;
