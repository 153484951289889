import React from 'react';

import cardImage from '@/images/Pricing/cardImage.png';

import { AI } from '@/components/AI';
import { Button, ButtonColors, Icon, Name, Switch } from '@/components/general';
import { LocalKeys } from '@/utils/constants';
import * as styled from './Card.styled';
import { ICardType, ICardTypeColor } from '../../constants';

interface ICard {
  card: ICardType;
  buildForMeMode?: boolean;
  yearly: boolean;
  setYearly: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Card: React.FC<ICard> = ({
  card,
  buildForMeMode,
  yearly,
  setYearly,
}) => {
  const onClick = () => {
    sessionStorage.setItem(LocalKeys.PRICING_PLAN, card.title);
    sessionStorage.setItem(LocalKeys.APP_PERIOD, yearly ? 'year' : 'month');
  };

  const button = (
    <Button
      height={36}
      color={
        card.color === ICardTypeColor.lightBlue
          ? ButtonColors.PRIMARY
          : card.color === ICardTypeColor.blue
          ? ButtonColors.SECONDARY
          : ButtonColors.BLACK
      }
      onClick={onClick}
      to={card.to}
    >
      {card.buttonText}
    </Button>
  );

  return (
    <styled.Box $buildForMeMode={buildForMeMode} $color={card.color}>
      <styled.TitleWrapper>
        <styled.Title>{card.title}</styled.Title>
        {card.getPlanPriceId && (
          <Switch label="Yearly" value={yearly} onChange={setYearly} />
        )}
      </styled.TitleWrapper>
      {buildForMeMode && (
        <styled.Description>{card.description}</styled.Description>
      )}
      <>
        {buildForMeMode && (
          <styled.SmallDescription $semiBold>
            Starting at
          </styled.SmallDescription>
        )}
        <styled.Price $buildForMeMode={buildForMeMode}>
          {card.price !== undefined
            ? yearly
              ? `$${card.yearPrice}`
              : `$${card.price}`
            : card.priceText}
          {buildForMeMode && (
            <styled.SmallDescription> per project</styled.SmallDescription>
          )}
        </styled.Price>
      </>
      {!buildForMeMode && (
        <styled.Description>{card.description}</styled.Description>
      )}
      <styled.ButtonBox $buildForMeMode={buildForMeMode}>
        {card.getPlanPriceId ? (
          <AI intent="start-building" planPriceId={card.getPlanPriceId(yearly)}>
            {button}
          </AI>
        ) : (
          button
        )}
      </styled.ButtonBox>
      <styled.FeaturesHeading $buildForMeMode={buildForMeMode}>
        {card.featuresHeading}
      </styled.FeaturesHeading>
      <styled.FeaturesList>
        {card.features.map((feature) => (
          <styled.ListItem key={feature}>
            <styled.VoteBox>
              <Icon name={Name.GENERAL_PLANS_CHECK_MARK} />
            </styled.VoteBox>
            <styled.ItemText>{feature}</styled.ItemText>
          </styled.ListItem>
        ))}
      </styled.FeaturesList>
      {buildForMeMode && card.color === ICardTypeColor.blue && (
        <styled.Image src={cardImage} alt={cardImage} />
      )}
    </styled.Box>
  );
};
