import React from 'react';
import { graphql } from 'gatsby';

import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import { BlogContent } from '@/components/Blog';
import { Footer } from '@/components/Footer';
import { Links } from '@/utils/constants';
import { Meta } from '@/components/general/Meta';

const Blog = ({ pageContext, data }) => (
  <>
    <Meta
      title="Blog | CodePlatform"
      description={data.contentfulBlogPage.description.description}
      url={Links.BLOG}
      robots={pageContext.tag ? 'noindex' : 'index'}
    />
    <GlobalStyle />
    <Wrapper>
      <Header currentpath={Links.BLOG} />
      <BlogContent currentTag={pageContext.tag} data={data} />
      <Footer />
    </Wrapper>
  </>
);

export default Blog;

export const query = graphql`
  query BlogPageQuery {
    contentfulBlogPage {
      title
      description {
        description
      }
      tags
      feature {
        title
        description {
          description
        }
        tags
        primaryImage {
          url
        }
      }
      articles {
        id
        title
        tags
        description {
          description
        }
        primaryImage {
          gatsbyImageData(width: 1000, placeholder: BLURRED)
          description
        }
      }
    }
  }
`;
