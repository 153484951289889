import React from 'react';

import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import { Content } from '@/components/Errors/404';
import { Meta } from '@/components/general/Meta';

const NotFound = () => (
  <>
    <Meta title="404 error | CodePlatform" robots="noindex" />
    <GlobalStyle />
    <Wrapper>
      <Header error />
      <Content />
    </Wrapper>
  </>
);

export default NotFound;
