import React, { useState } from 'react';
import { graphql } from 'gatsby';

import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import { Content } from '@/components/Templates';
import { Footer } from '@/components/Footer';
import { Links } from '@/utils/constants';
import { Meta } from '@/components/general/Meta';

const Templates = ({ pageContext, data }) => {
  const [description, setDescription] = useState(
    data.contentfulTemplatePage.description.description,
  );
  const [title, setTitle] = useState(data.contentfulTemplatePage.title);

  return (
    <>
      <Meta
        title={`${title} | CodePlatform`}
        description={description}
        url={Links.TEMPLATES}
        robots={
          pageContext.category ||
          pageContext.technology ||
          pageContext.language ||
          pageContext.feature
            ? 'noindex'
            : 'index'
        }
      />
      <GlobalStyle />
      <Wrapper>
        <Header currentpath={Links.TEMPLATES} />
        <Content
          data={data.contentfulTemplatePage}
          currentCategory={pageContext.category}
          currentTechnology={pageContext.technology}
          currentLanguage={pageContext.language}
          currentFeature={pageContext.feature}
          setTitle={setTitle}
          setDescription={setDescription}
        />
        <Footer />
      </Wrapper>
    </>
  );
};

export default Templates;

export const query = graphql`
  query TemplatePageQuery {
    contentfulTemplatePage {
      title
      description {
        description
      }
      categories
      technologies
      languages
      features
      templates {
        id
        title
        mainIcon {
          url
        }
        additionalIcon {
          url
        }
        description {
          description
        }
        categories
        technologies
        languages
        features {
          feature
          description {
            description
          }
        }
        studioImage {
          url
        }
        studioTemplateId
        previewUrl
      }
    }
  }
`;
